import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../containers/Home";
import Curso from "../containers/Curso";
import Layout from "../components/Layout";
import UsarContexto from "../context/UsarContexto";
import MisCursos from "../containers/MisCursos";
import Examenes from "../containers/Examenes";
import MiPerfil from "../containers/MiPerfil";
import Carrito from "../containers/Carrito";
import "../assets/css/App.css";
import Ofertas from "../containers/Ofertas.js";
import P404 from "../containers/P404.js";
import Ver from "../containers/Ver.js";
import Renovaciones from "../containers/Renovaciones";
import Gratis from "../containers/Gratis";
import Faq from "../containers/Faquestion";
import Certificados from "../containers/Certificados";
import Podcast from "../containers/Podcast";
import Coming from "../containers/Coming";
import DesignSeason from "../containers/DesignSeason";
import Leeme from "../components/Leeme";
import AnvorGame from "../containers/AnvorGame";
// import Summerhack from "../containers/Summerhack2024";
import Privacidad from "../containers/Privacidad";
import Summerana from "../containers/WinterHack.js";
import EasterHack from "../containers/EasterHack.js";
import Records from "../containers/Records.jsx";
import Winter24 from "../containers/Winter24.js";
import Capsulas from "../containers/Capsulas.js";
function App() {
  return (
    <Router>
      <UsarContexto>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route exact path="/capsulas/" element={<Capsulas />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/sus" element={<Coming />}></Route>
            <Route exact path="/curso/" element={<Curso />}></Route>
            <Route exact path="/miscursos/" element={<MisCursos />}></Route>
            <Route exact path="/examenes/*" element={<Examenes />}></Route>
            <Route exact path="/MiPerfil/" element={<MiPerfil />}></Route>
            <Route exact path="/carrito/" element={<Carrito />}></Route>
            <Route exact path="/ofertas/" element={<Ofertas />}></Route>
            <Route exact path="/gratis/" element={<Gratis />}></Route>
            <Route exact path="/podcast/" element={<Podcast />}></Route>
            <Route path="/certificados/*" element={<Certificados />}></Route>
            <Route exact path="/faq/" element={<Faq />}></Route>
            <Route exact path="/ver/" element={<Ver />}></Route>
            <Route exact path="/leer/" element={<Leeme />}></Route>
            {/* <Route exact path="/winterhack" element={<WinterHack />}></Route> */}
            {/* <Route exact path="/summerhack" element={<Summerhack />}></Route> */}
            <Route exact path="/summerana" element={<Summerana />}></Route>
            <Route exact path="/EasterHack" element={<EasterHack />}></Route>
            <Route exact path="/winterhack" element={<Winter24 />}></Route>
            <Route exact path="/anvorgame" element={<AnvorGame />}></Route>
            <Route exact path="/records" element={<Records />}></Route>

            <Route
              exact
              path="/politicas-de-privacidad"
              element={<Privacidad />}
            ></Route>
            {/* <Route
              exact
              path="/designseason/"
              element={<DesignSeason />}
            ></Route> */}
            <Route exact path="/404/" element={<P404 />}></Route>
            <Route path="/ver/*" element={<Ver />}></Route>
            <Route
              exact
              path="/renovaciones"
              element={<Renovaciones />}
            ></Route>
            <Route path="*" element={<Curso />}></Route>
          </Routes>
        </Layout>
      </UsarContexto>
    </Router>
  );
}

export default App;
